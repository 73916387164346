import React, { useState, useEffect, useRef } from "react";
import { Eye, Radio, AlertTriangle, MapIcon, X } from 'lucide-react';
import SpaceshipMap from './SpaceshipMap'; // Placeholder for the future SpaceshipMap component

const TypewriterText = ({ text = '', delay = 50, onComplete }) => {
  const [displayText, setDisplayText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < text.length) {
      const timer = setTimeout(() => {
        setDisplayText((prev) => prev + text[currentIndex]);
        setCurrentIndex((prev) => prev + 1);
      }, delay);
      return () => clearTimeout(timer);
    } else if (onComplete) {
      onComplete();
    }
  }, [currentIndex, delay, text, onComplete]);

  return <span className="font-mono">{displayText}<span className="animate-pulse">_</span></span>;
};

const TerminalGame = () => {
  const [messageIndex, setMessageIndex] = useState(0);
  const [userInput, setUserInput] = useState('');
  const [commandHistory, setCommandHistory] = useState([]);
  const [currentPath, setCurrentPath] = useState('bridge');
  const [inventory, setInventory] = useState([]);
  const [blueprintFragments, setBlueprintFragments] = useState(0);
  const [missionComplete, setMissionComplete] = useState(false);
  const [showMap, setShowMap] = useState(false); // Control map visibility on smaller screens

  const initialMessages = [
    "/// SYSTEM ALERT: SHIP BREACHED ///",
    "This is [REDACTED]. If you're receiving this, you're one of the chosen few.",
    "The Cabal fears our knowledge of Autistic Intelligence AI (AIAI) might disrupt their control over the world's systems.",
    "They're after our research and the neural architecture hidden within the ship's blueprints.",
    "WARNING: Enemy proximity increasing. All movement and communication is being monitored.",
    "Objective: Secure the 5-part Blueprint hidden across different modules of the ship.",
    "Be cautious: The Cabal has inserted traps and decoy logs to mislead you.",
    "Type 'help' to review available commands and initiate the mission."
  ];

  const shipMap = {
    bridge: ["cargo hold", "security room", "crew quarters"],
    "cargo hold": ["bridge", "engineering", "AI lab"],
    "security room": ["bridge"],
    "crew quarters": ["bridge", "med bay"],
    "med bay": ["crew quarters", "engineering"],
    engineering: ["cargo hold", "med bay", "AI lab"],
    "AI lab": ["engineering"]
  };

  const collectibleItems = {
    bridge: ["blueprint fragment"],
    "cargo hold": ["data chip", "power cell"],
    "security room": ["security keycard"],
    "crew quarters": ["encrypted note"],
    "med bay": ["antiviral serum"],
    engineering: ["power cell", "toolkit"],
    "AI lab": ["prototype AI chip"]
  };

  const itemCombinations = {
    "data chip security keycard": {
      result: "override access",
      message: "You successfully override the control panel and gain access to the AI lab!"
    },
    "toolkit power cell": {
      result: "powered toolkit",
      message: "You assemble a powered toolkit, which might help in unlocking damaged doors."
    }
  };

  const threats = {
    "security room": "Unstable energy readings detected",
    "AI lab": "Cabal operatives detected nearby",
  };

  // Mission Complete Check
  useEffect(() => {
    if (blueprintFragments === 5 && !missionComplete) {
      setMissionComplete(true);
      setCommandHistory((prev) => [
        ...prev,
        "Mission Complete! You've secured all 5 blueprint fragments.",
        "But this is just the beginning. The Cabal is relentless, still hunting for the remaining blueprints.",
        "We need to assemble our own agent and protect AIAI's secrets from the Cabal’s grasp.",
        "Stay tuned for the next phase of the mission – more challenges await as we move forward in safeguarding AIAI."
      ]);
    }
  }, [blueprintFragments, missionComplete]);

  // Flexible Command Handling
  const handleCommand = (e) => {
    e.preventDefault();
    const command = userInput.toLowerCase().trim();
    setCommandHistory((prev) => [...prev, `> ${command}`]);

    if (command === 'help') {
      setCommandHistory((prev) => [
        ...prev,
        "> Available Command Categories:",
        "  - move [location] or go [location] - Move to a different room or area.",
        "  - paths - Show available paths from your current location.",
        "  - collect [item] or pick up [item] - Pick up an item in the current location.",
        "  - combine [item1] [item2] - Combine two items in your inventory.",
        "  - inspect [object] - Look at an object for details.",
        "  - decrypt [target] - Decode encrypted data or access locked systems.",
        "  - locate [room] - Find items, logs, and threats in a specified room.",
        "  - status - View mission progress.",
        "  - clear - Clear the terminal screen."
      ]);
    }

    else if (command.startsWith("move ") || command.startsWith("go ")) {
      const locationInput = command.replace(/^move\s+(?:to\s+)?|^go\s+(?:to\s+)?/, '').trim();
      const targetLocation = Object.keys(shipMap).find(loc => loc === locationInput);

      if (targetLocation && shipMap[currentPath].includes(targetLocation)) {
        setCurrentPath(targetLocation);
        const items = collectibleItems[targetLocation] || [];
        const threat = threats[targetLocation] || "None";

        setCommandHistory((prev) => [
          ...prev,
          `> Moved to ${targetLocation.toUpperCase()}.`,
          `Items: ${items.join(", ") || "None"}`,
          `Threats: ${threat}`,
        ]);

      } else {
        setCommandHistory((prev) => [...prev, "> Can't move to that location. Use 'paths' to check options."]);
      }
    }

    else if (command === "paths") {
      setCommandHistory((prev) => [
        ...prev,
        `> Available paths from ${currentPath}: ${shipMap[currentPath].join(", ")}.`
      ]);
    }

    else if (command.startsWith("collect") || command.startsWith("pick up")) {
      const itemToCollect = command.replace(/^(collect|pick up)\s+/, '').trim().toLowerCase();
      const itemsInRoom = (collectibleItems[currentPath] || []).map(item => item.toLowerCase());

      if (itemsInRoom.includes(itemToCollect) && !inventory.includes(itemToCollect)) {
        setInventory((prev) => [...prev, itemToCollect]);
        setCommandHistory((prev) => [
          ...prev,
          `> Collected ${itemToCollect}.`
        ]);
        if (itemToCollect === "blueprint fragment") {
          setBlueprintFragments((prev) => prev + 1);
        }
      } else {
        setCommandHistory((prev) => [
          ...prev,
          `> There's no such item ("${itemToCollect}") in the current location (${currentPath}) to collect.`
        ]);
      }
    }

    else if (command === "status") {
      setCommandHistory((prev) => [
        ...prev,
        "> STATUS REPORT:",
        `Location: ${currentPath}`,
        `Blueprint Fragments: ${blueprintFragments}/5`,
        `Inventory: ${inventory.join(", ") || "Empty"}`
      ]);
    }

    else if (command === "clear") {
      setCommandHistory([]);
    } else {
      setCommandHistory((prev) => [
        "> Unknown command. Type 'help' for assistance."
      ]);
    }

    setUserInput('');
  };

  return (
      <div className="min-h-screen flex items-center justify-center bg-black p-2 md:p-4">
        <div className="flex flex-col w-full max-w-6xl mx-auto">
          <div className="md:hidden mb-2 flex justify-end">
            <button onClick={() => setShowMap(!showMap)} className="bg-white/10 p-2 rounded-lg text-white/80 hover:bg-white/20">
              {showMap ? <X className="w-5 h-5" /> : <MapIcon className="w-5 h-5" />}
            </button>
          </div>

          <div className="flex flex-col md:flex-row gap-4">
            {/* Terminal Display */}
            <div className="w-full md:w-2/3 bg-black border border-white/20 rounded-lg p-4 md:p-6 font-mono">
              <div className="flex items-center justify-between mb-4 md:mb-6">
                <div className="flex items-center gap-2 md:gap-3">
                  <Eye className="w-4 h-4 md:w-5 md:h-5 text-white/80" />
                  <span className="text-sm md:text-base text-white/80">AUTISTIC_TERMINAL</span>
                </div>
                <div className="flex items-center gap-2 md:gap-3">
                  <Radio className="w-3 h-3 md:w-4 md:h-4 animate-pulse text-white/60" />
                  <span className="text-xs text-white/60">{missionComplete ? "ESCAPE" : "SEARCHING"}</span>
                </div>
              </div>

              <div className="space-y-2 md:space-y-3 text-xs md:text-sm text-white/80 mb-4 md:mb-6 min-h-[200px] max-h-[300px] overflow-y-auto scrollbar-thin scrollbar-thumb-white/20 scrollbar-track-transparent">
                {initialMessages.slice(0, messageIndex + 1).map((msg, idx) => (
                    <div key={idx} className={`${idx === messageIndex ? '' : 'opacity-60'}`}>
                      {idx === messageIndex ? (
                          <TypewriterText text={msg} onComplete={() => {
                            if (messageIndex < initialMessages.length - 1) {
                              setTimeout(() => setMessageIndex((prev) => prev + 1), 800);
                            }
                          }} />
                      ) : msg}
                    </div>
                ))}

                {commandHistory.map((cmd, idx) => (
                    <div key={`cmd-${idx}`} className="opacity-60">
                      {cmd}
                    </div>
                ))}
              </div>

              <form onSubmit={handleCommand} className="mt-4">
                <div className="flex items-center">
                  <span className="text-white/80 mr-2">&gt;</span>
                  <input
                      type="text"
                      value={userInput}
                      onChange={(e) => setUserInput(e.target.value)}
                      className="bg-transparent border-none outline-none text-white/80 w-full"
                      autoFocus
                  />
                </div>
              </form>
            </div>

            {/* Right Panel with Map and Status */}
            <div className={`md:w-1/3 flex flex-col gap-4 ${showMap ? 'hidden md:flex' : 'flex'}`}>
              <div className="hidden md:block">
                <SpaceshipMap currentLocation={currentPath} /> {/* Placeholder for future map */}
              </div>
              <div className="bg-black border border-white/20 rounded-lg p-3 md:p-4">
                <div className="text-xs text-white/60 mb-2 md:mb-3">SYSTEM STATUS</div>
                <div className="space-y-1 md:space-y-2 text-xs md:text-sm">
                  <div className="flex justify-between text-white/80">
                    <span>Blueprint Fragments:</span>
                    <span>{blueprintFragments}/5</span>
                  </div>
                  <div className="flex justify-between text-white/80">
                    <span>Inventory:</span>
                    <span>{inventory.join(", ") || "Empty"}</span>
                  </div>
                  <div className="flex justify-between text-white/80">
                    <span>Location:</span>
                    <span>{currentPath}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default TerminalGame;
