import React from 'react';
import ARGGame from './components/ARGGame';

function App() {
    return (
        <div className="App">
            <ARGGame />
        </div>
    );
}

export default App;