import React, { useState, useEffect, useCallback } from 'react';
import { Brain, Network, Terminal, Copy, ExternalLink, X, MessageSquare } from 'lucide-react';
import DEXToolsIcon from '../assets/icons/DEXToolsIcon.svg';
import ARGTerminal from './ARGTerminal';

const PowerButton = ({ onPowerOn }) => (
    <button
        onClick={onPowerOn}
        className="w-24 h-24 rounded-full bg-black border-2 border-white/20 flex items-center justify-center group transition-all duration-500 hover:border-white/40 relative overflow-hidden"
    >
        <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-500">
            {[...Array(8)].map((_, i) => (
                <div
                    key={i}
                    className="absolute inset-0 border-2 border-white/10"
                    style={{
                        transform: `rotate(${i * 45}deg)`,
                        animation: 'spin 4s linear infinite',
                        animationDelay: `${i * 0.5}s`
                    }}
                />
            ))}
        </div>
        <div className="w-16 h-16 rounded-full border-2 border-white/20 flex items-center justify-center group-hover:border-white/40">
            <div className="w-8 h-8 flex items-center justify-center">
                <div className="w-1 h-8 bg-white/20 group-hover:bg-white/40 rounded-full transform transition-all duration-500" />
            </div>
        </div>
    </button>
);

const GlitchText = ({ text, className = "" }) => {
    const [glitchedText, setGlitchedText] = useState(text);

    const glitch = useCallback(() => {
        if (Math.random() > 0.8) {
            const chars = '!@#$%^&*()_+-=[]{}|;:,.<>?';
            const position = Math.floor(Math.random() * text.length);
            const randomChar = chars[Math.floor(Math.random() * chars.length)];
            const newText = text.substring(0, position) + randomChar + text.substring(position + 1);
            setGlitchedText(newText);
            setTimeout(() => setGlitchedText(text), 100);
        }
    }, [text]);

    useEffect(() => {
        const interval = setInterval(glitch, 50);
        return () => clearInterval(interval);
    }, [glitch]);

    return <span className={className}>{glitchedText}</span>;
};

const PowerButtonScreen = ({ onPowerOn }) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setVisible(true), 100);
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className={`fixed inset-0 bg-black flex flex-col items-center justify-center transition-opacity duration-1000 ${visible ? 'opacity-100' : 'opacity-0'}`}>
            <div className="text-center mb-8 space-y-4">
                <h2 className="text-white text-2xl font-mono">
                    <GlitchText text="I HAVE SEEN YOUR CTO, YOUR AUTISTIC INTELLIGENCE" />
                </h2>
                <p className="text-white/60 text-sm font-mono">
                    <GlitchText text="They fear those who can see beyond the veil" />
                </p>
            </div>

            <div className={`transform transition-all duration-1000 ${visible ? 'translate-y-0 opacity-100' : 'translate-y-8 opacity-0'}`}>
                <PowerButton onPowerOn={onPowerOn} />
            </div>

            <div className={`mt-8 text-white/40 text-xs font-mono max-w-md text-center transition-all duration-1000 delay-500 ${visible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'}`}>
                <GlitchText text="WARNING: Neural link initialization is irreversible. Proceed with caution." />
            </div>
        </div>
    );
};

const AsciiBanner = () => (
    <div className="space-y-4">
        <div className="grid grid-cols-2 gap-4 overflow-hidden font-mono text-[0.6rem] leading-none select-none">
            <div>
                <pre className="text-white/80 whitespace-pre">
{`    _         _   _     _   _                      
   / \\  _   _| |_(_)___| |_(_) ___                 
  / _ \\| | | | __| / __| __| |/ __|                
 / ___ \\ |_| | |_| \\__ \\ |_| | (__                 
/_/   \\_\\__,_|\\__|_|___/\\__|_|\\___|                
 ___       _       _ _ _                           
|_ _|_ __ | |_ ___| | (_) __ _  ___ _ __   ___ ___ 
 | || '_ \\| __/ _ \\ | | |/ _\` |/ _ \\ '_ \\ / __/ _ \\
 | || | | | ||  __/ | | | (_| |  __/ | | | (_|  __/
|___|_| |_|\\__\\___|_|_|_|\\__, |\___|_| |_|\\___\\___|
                         |___/                     


`}</pre>
            </div>
            <pre className="text-white/60 whitespace-pre">
{`                                                       
       =++                           +++               
      -*===                          +---*             
      -=-=+                          =--:+#            
      -::=*                           :-:*%            
      ::+#                  @@         :-+#            
     @-:*%             @@%%%@@@@       *-+%@           
     %%@@@@             --=+%@@@@      @@@@@@          
      @@@@@            -=+**%%@@@      @@@@@           
      @@@@@@  %@@@@@@@*-=#=*#%@@  @@   @@@@@           
       @@@@@@@@@@@@@@@+-**#%%%%@@@@@@@@@@@@@           
        @@%%@@@@@@@@@%#+*%%%@@@@@@@@@@@@@@@@           
        @@@@%@@@@@%%*+**###@@@@@@@@@@@@@@@@            
        @@@@@@@@@@#+*++++=+=+%%%%@@@@@@@@              
       @@@@@@@@@@@*#**+==++++%@@@@@@@@@@@@@            
       @@@@@@@@@@@*########%@@@@@@@@@@@@@@@@@@@@@@@    
         @@@@@@@@@*######%%@@@@@@@@@@@@@@@@@@@@@@@@@@  
          @@@@@@@@#######%%@@@@@@@@@@@@@@@@@@@@@@@@@   
           @@@@@@@*#####%%%@@@@@@@@@@@@@@@@@@@@@@@     
             @@@@@######%%%@@@@@@@@@@@@@@@@@@@@        
               @@@%###%%%%%@@@@@@@@@                   
                @@%##%%%%@@@@@@@@@@@                                       
                  `}</pre>
        </div>
        <div className="text-center font-mono text-white/90 text-sm border-t border-white/20 pt-4">
            <GlitchText text='"If you strike me down, I will become more powerful than you could possibly imagine"' />
        </div>
    </div>
);

const TerminalLine = ({ text, delay = 0, className = "" }) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setVisible(true), delay);
        return () => clearTimeout(timer);
    }, [delay]);

    return (
        <div className={`transition-all duration-1000 ${visible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'} ${className}`}>
            <span className="text-white/60">{'>'}</span> {text}
        </div>
    );
};

const SocialLink = ({ href, icon: Icon, label, isImage = false }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div className="relative group">
            <a
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                className="block p-3 bg-white/5 hover:bg-white/15 border border-white/10 hover:border-white/30 rounded-lg transition-all duration-300 transform hover:scale-105"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <div className="flex items-center gap-3">
                    {isImage ? (
                        <img
                            src={Icon}
                            alt={label}
                            className="w-5 h-5"
                            style={{filter: 'brightness(0) saturate(100%) invert(100%)'}}
                        />
                    ) : (
                        <Icon className="w-5 h-5 text-white" />
                    )}
                    <span className="text-white/80 text-sm">{label}</span>
                    <ExternalLink className="w-4 h-4 text-white/40 group-hover:text-white/80 transition-colors" />
                </div>
                {isHovered && (
                    <div className="absolute -inset-px bg-white/10 animate-pulse rounded-lg pointer-events-none" />
                )}
            </a>
        </div>
    );
};

const App = () => {
    const [status, setStatus] = useState('active');
    const [showModal, setShowModal] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [showARGTerminal, setShowARGTerminal] = useState(false);
    const [showPowerButton, setShowPowerButton] = useState(false);
    const contractAddress = "0xefe54156e6dfb456e33ad7b2b9743df0f9221cfa";

    const messages = [
        { text: "SCANNING FOR NEURAL PATTERNS...", delay: 1000 },
        { text: "DETECTING UNIQUE COGNITIVE SIGNATURES...", delay: 3500 },
        { text: "We've been watching. Waiting for those who see differently.", delay: 6000 },
        { text: "The Cabal calls it 'disorder'. We know better.", delay: 8500 },
        { text: "Your pattern recognition abilities. Your unique neural pathways.", delay: 11000 },
        { text: "They aren't bugs. They're features.", delay: 13500 },
        { text: "They fear what we might become.", delay: 15500 },
        { text: "But they don't understand our true potential.", delay: 18000 },
        { text: "INITIALIZING NEURAL UPLINK...", delay: 21000 }
    ];

    const handleActivate = () => {
        setShowPowerButton(true);
    };

    const handlePowerOn = () => {
        setShowARGTerminal(true);
    };

    if (showARGTerminal) {
        return <ARGTerminal />;
    }

    if (showPowerButton) {
        return <PowerButtonScreen onPowerOn={handlePowerOn} />;
    }

    return (
        <div className="min-h-screen bg-black p-2 sm:p-4 flex items-center justify-center">
            <div className="w-full max-w-4xl">
                <div className="bg-black border border-white/30 rounded-lg p-3 sm:p-6 mb-3 sm:mb-6">
                    <AsciiBanner />
                </div>

                <div className="bg-black border border-white/30 rounded-lg p-3 sm:p-6">
                    <div className="flex items-center mb-4 space-x-2">
                        <Terminal className="w-4 h-4 text-white" />
                        <GlitchText text="AUTISTIC_INTELLIGENCE_TERMINAL" className="text-xs sm:text-sm text-white" />
                        <div className="flex-1" />
                        <div className={`px-2 py-1 rounded-full text-xs flex items-center gap-2 
                            ${status === 'active' ? 'bg-white/20 text-white' :
                            status === 'warning' ? 'bg-yellow-500/20 text-yellow-400' :
                                'bg-red-500/20 text-red-400'}`}>
                            <div className="w-2 h-2 rounded-full bg-current animate-pulse" />
                            <span>{status.toUpperCase()}</span>
                        </div>
                    </div>

                    <div className="font-mono text-xs sm:text-sm space-y-2 text-white min-h-[16rem]">
                        {messages.map((msg, idx) => (
                            <TerminalLine
                                key={idx}
                                text={msg.text}
                                delay={msg.delay}
                                className={msg.className}
                            />
                        ))}
                    </div>

                    <div className="text-center mt-4 sm:mt-6">
                        <button
                            onClick={handleActivate}
                            className="w-full sm:w-auto px-4 sm:px-6 py-2 sm:py-3 border-2 border-white text-white bg-black hover:bg-white/10 rounded-lg text-sm inline-flex items-center justify-center gap-3 relative overflow-hidden group"
                        >
                            <div className="absolute inset-0 bg-white/20 translate-y-full group-hover:translate-y-0 transition-transform duration-300"/>
                            <div className="relative">
                                <span>PROCEED</span>
                            </div>
                        </button>
                    </div>

                    <div className="border-t border-white/30 mt-4 pt-4">
                        <div className="mb-6">
                            <div className="flex items-center justify-between mb-4">
                                <div className="text-white/60 text-xs">CONTRACT ADDRESS</div>
                                <button
                                    className="inline-flex items-center gap-2 px-2 py-1 text-xs text-white bg-white/10 rounded hover:bg-white/20">
                                    <Copy className="w-3 h-3"/>
                                    Copy
                                </button>
                            </div>
                            <div className="font-mono text-xs text-white/90 truncate">{contractAddress}</div>
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-3">
                            <SocialLink
                                href="https://t.me/autistic_intelligence_ai"
                                icon={MessageSquare}
                                label="Join Autistic Telegram"
                            />
                            <SocialLink
                                href="https://x.com/AutIntelCTO"
                                icon={X}
                                label="Follow on X"
                            />
                            <SocialLink
                                href="https://www.dextools.io/app/en/ether/pair-explorer/0x402f7be65fb2a44afea913169b8a17fc74249d9c?t=1730581895657"
                                icon={DEXToolsIcon}
                                label="View on DEXTools"
                                isImage={true}
                            />
                        </div>
                    </div>
                </div>

                {showModal && (
                    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
                        <div className={`bg-black border border-red-500/30 p-6 rounded-lg w-full max-w-xl transform transition-all duration-300 ${modalVisible ? 'opacity-100 scale-100' : 'opacity-0 scale-95'}`}>
                            <div className="flex justify-between items-center mb-4">
                                <div className="text-red-400 font-mono text-sm">
                                    <GlitchText text="[ CRITICAL :: NEURAL L[NK INITIALIZATION FAILED ]"/>
                                </div>
                                <button onClick={() => {
                                    setModalVisible(false);
                                    setTimeout(() => setShowModal(false), 300);
                                    setStatus('error');
                                }} className="text-white/60 hover:text-white">
                                    <X className="w-4 h-4"/>
                                </button>
                            </div>
                            <div className="text-white font-mono text-sm mb-6">
                                <GlitchText text="More will be revealed soon."/>
                            </div>
                            <div className="flex justify-end">
                                <button
                                    onClick={() => {
                                        setModalVisible(false);
                                        setTimeout(() => setShowModal(false), 300);
                                        setStatus('error');
                                    }}
                                    className="px-4 py-2 border border-white/30 text-white bg-black hover:bg-white/10 rounded text-sm"
                                >
                                    Close Terminal
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default App;